/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { alternateBorders } from "../css/css";
import React from "react";

export function Instructions(props: { closeFunction: any }) {
  return (
    <div css={appContainerCss}>
      <button
        onClick={() => {
          props.closeFunction();
        }}
      >
        Close X
      </button>
      <div>Contact:info@revchess.com</div>
      <ul>
        <li css={listCss}>
          Overview of what the app can do:
          <ul>
            <li>
              "Consistency Check": Determine if your opening recommends two
              different moves for the same postion
            </li>
            <li>
              "Coverage Check": Based on data from Lichess's billions of games,
              determine percentage of moves your opening covers at any
              particular position
            </li>
            <li>
              "Compare game to opening":Determine what move in a game went out
              of the opening
            </li>
          </ul>
        </li>
        <li css={listCss}>
          Enter Opening (one of two ways):
          <ul>
            <li>
              Upload pgn:<br></br>You can upload a pgn file
            </li>
            <li>
              Lichess Username:<br></br>If you provide a username and click "Get
              Studies", a list of all public studies for that user will appear
              and you can select one to use.
            </li>
            <li>
              Properties: Indicate which color the opening is for and choose, if
              any, the indicators you marked your opening with to indicate bad
              moves.
            </li>
            <li>Consistency Check</li>
            <ul>
              <li>
                Click "Do Consistency Check". In the results section the app
                will identify all board positions (fens), where the opening
                recommends more than one move. It will show those moves and all
                chapters in the study and all pgn's that lead to that
                recommendation. (Note this is optional and not necessary to use
                the other functions)
              </li>
            </ul>
          </ul>
        </li>
        <li>Opening Coverage</li>
        <ul css={listCss}>
          <li>
            Based on the game filters you have selected, the results section
            will have each unique position where it is your opponents turn. For
            every position it will show the following (note: Ratings filters are
            the same as on Lichess, each value check will include all games at
            that rating and above to the next rating. So selecting just 1200
            will use all games from 1200 to 1400. You can select more than
            one.):
            <ul>
              <li>Pgn leading up to that position</li>
              <li>The FEN for that position</li>
              <li>Total Games leading up to that position</li>
              <li>
                Total coverage in percentage of moves opponents make your
                opening has covered
              </li>
              <li>
                For each opponent move your opening includes, what is the
                percentage of times that move is made
              </li>
            </ul>
            <li>
              (Note:This is optional and not necessary to use the other
              functions. This can take 1-2 seconds per FEN to run. To respect
              Lichess's rate limits, there is a delay between getting data for
              each FEN. The system stores the results of these calls so
              subsequent coverage checks will be much faster and if any other
              coverage check by any other user has the same FEN's those won't
              need to be gotten. But depending on how many FEN's are not yet in
              the database, a 1200 position opening can take up to approximately
              30 minutes to run. If many of the positions are already in the
              system, it takes 15 seconds to a minute)
            </li>
          </li>
        </ul>
        <li>Enter game(s) in one of three ways:</li>

        <ul css={listCss}>
          <li>Upload pgn file</li>
          <li>Paste pgn</li>
          <li>
            Note:The file or pgn can have more than one game and the app will
            compare each one
          </li>
          <li>
            Select a game from Lichess or Chess.com by providing a username and
            clicking either Get Lichess or Get Chess.com. App will get last 80
            games at Lichess or last 2 months of games at Chess.com
          </li>
        </ul>
        <li>
          Comparing game(s) to Opening:<br></br>Click the "Compare game(s) to
          opening" button and the app will display in the resuls area the
          following:
          <ul>
            <li> What move by either player deviated from the opening</li>
            <li>
              What move by either player brought the game back into the opening
              after leaving.
            </li>
            <li>What move went beyond the moves in the opening.</li>
          </ul>
          The results section will show the move(s) that went out of prep or
          brought you back into prep, the pgn leading up to that move and what
          move(s) the opening did cover and the chapter of the opening that
          recommendation was in.
        </li>
      </ul>
    </div>
  );
}

const appContainerCss = css`
  ${alternateBorders()}
`;

const listCss = css`
  margin-bottom: 10px;
`;
